export const truncateText = (text, limit) => {
	if (!text) return '';
	if (text.length < limit) return text;
	return text.substring(0, limit) + '...';
};

export const cleanLongStrings = (text) => {
	// Used when words are too long to fit in a container
	// replace underscores and hyphens with soft hyphens
	if (!text?.length) return text;

	let str = text;

	// For long strings we want to replace underscores with soft hyphens to allow line breaks
	if (str.length > 15) str = hypthenizeUnderscores(str);

	// Replace hyphens with soft hyphens
	str = softHyphenize(str);

	return str;
};

export const softHyphenize = (text) => {
	if (!text) return undefined;
	return text?.replace(/([-])/g, '\u00AD');
};

export const hypthenizeUnderscores = (text) => {
	if (!text) return undefined;
	return text?.replace(/([_])/g, '\u00AD');
};
